(function($) {
  $('.bxslider').bxSlider({
    slideSelector: '.slide',
    mode: 'horizontal',
    minSlides: 1,
    maxSlides: 1,
    slideWidth: 0,
    controls: true,
    keyboardEnabled: true,
    nextSelector: '#slider-next',
    prevSelector: '#slider-prev',
    nextText: 'Next',
    prevText: 'Prev',
    pager: false,
    adaptiveHeight: true,
    hideControlOnEnd: true
  });
})(jQuery);
